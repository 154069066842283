import { render, staticRenderFns } from "./configure.vue?vue&type=template&id=2061e155&scoped=true"
import script from "./configure.vue?vue&type=script&lang=js"
export * from "./configure.vue?vue&type=script&lang=js"
import style0 from "./configure.vue?vue&type=style&index=0&id=2061e155&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2061e155",
  null
  
)

export default component.exports